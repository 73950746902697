<template>
  <Page>
    <span slot="actionbar">
      <div class="btn-group">
        <select class="form-control" v-model="selectedYear">
          <option value="2022">2022</option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
          <option value="2019">2019</option>
        </select>
      </div>
      <div class="btn-group">
          <a :class="[selectedDepartment == '' ? 'btn-primary' : 'btn-default']" @click="selectDepartment('')"
             class="btn">Groep</a>
          <a :class="[selectedDepartment == 1 ? 'btn-primary' : 'btn-default']" @click="selectDepartment(1)"
             class="btn">Dewaele</a>
          <a :class="[selectedDepartment == 2 ? 'btn-primary' : 'btn-default']" @click="selectDepartment(2)"
             class="btn">Turner</a>
      </div>
    </span>

    <span slot="title">Rapport: Verbruik</span>
    <div>
      <Loader message="Het rapport wordt opgebouwd" v-if="loading"></Loader>

      <table class="table table-hover" v-if="!loading">
        <thead>
        <tr>
          <th>Kantoor</th>
          <th class="text-right">Totaal budget</th>
          <th class="text-right">Potjes</th>
          <th class="text-right">Besteld</th>
          <th class="text-right">Gefactureerd</th>
          <th class="text-right">Openstaand</th>
        </tr>
        </thead>
        <tbody>
        <tr :key="reportData[officeData].budget_id" v-for="officeData in Object.keys(reportData)">
          <td>
            <router-link :to="{name: 'Budget', params: {id: reportData[officeData].budget_id}}" target="_blank">
              {{ reportData[officeData].office }}
            </router-link>
          </td>
          <td class="text-right">{{ reportData[officeData].total|currency }}</td>
          <td class="text-right">{{ reportData[officeData].reserved_total|currency }}</td>
          <td class="text-right">{{ reportData[officeData].ordered_total|currency }}</td>
          <td class="text-right">{{ reportData[officeData].invoiced_total|currency }}</td>
          <td class="text-right">{{ reportData[officeData].balance|currency }}</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th>Totaal</th>
          <th class="text-right">{{ totals.totalBudget|currency }}</th>
          <th class="text-right">{{ totals.totalReserved|currency }}</th>
          <th class="text-right">{{ totals.totalOrdered|currency }}</th>
          <th class="text-right">{{ totals.totalInvoiced|currency }}</th>
          <th class="text-right">{{ totals.totalBalance|currency }}</th>
        </tr>
        </tfoot>
      </table>
    </div>
  </Page>
</template>

<script>
import $ from 'jquery'

import Page from '@/components/iam/Page'
import Loader from '@/components/iam/Loader'

export default {
  name: 'UsageReport',
  components: { Loader, Page },
  data () {
    return {
      loading: false,
      selectedYear: 2019,
      selectedDepartment: '',
      report: {}
    }
  },
  watch: {
    selectedYear: {
      handler () {
        this.loadData()
      },
      immediate: true
    },
    selectedDepartment () {
      this.loadData()
    }
  },
  computed: {
    reportData () {
      return this.report
    },
    totals () {
      if (!this.reportData) {
        return {}
      }

      const totalData = {
        totalBudget: 0,
        totalReserved: 0,
        totalOrdered: 0,
        totalInvoiced: 0,
        totalBalance: 0
      }

      Object.keys(this.reportData).forEach(budget => {
        totalData.totalBudget += this.reportData[budget].total
        totalData.totalReserved += this.reportData[budget].reserved_total
        totalData.totalOrdered += this.reportData[budget].ordered_total
        totalData.totalInvoiced += this.reportData[budget].invoiced_total
        totalData.totalBalance += this.reportData[budget].balance
      })

      return totalData
    }
  },
  methods: {
    loadData () {
      this.loading = true
      this.report = {}
      $.ajax({
        url: '/api/budget/reports/usage',
        data: { year: this.selectedYear, department: this.selectedDepartment }
      }).done(data => {
        this.report = JSON.parse(data)
        this.loading = false
      }).fail((error) => {
        console.error(error)
        this.loading = false
      })
    },
    selectDepartment (departmentId) {
      this.selectedDepartment = departmentId
    }
  }
}
</script>
